<template>
  <div class="main-div vasion-calc-height">
    <div class="inner-div">
      <VasionGenericEmptyIcon id="vasion-empty-icon" />
      <div class="vasion-large-message">
        {{ greetingMessage }}
      </div>
      <div class="vasion-page-subheader instructional-message-div">
        {{ instructionalMessage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyFoldersView',
  data: function () {
    return {
      greetingMessage: 'Hello!',
    }
  },
  computed: {
    instructionalMessage() { return 'Please select a folder to get started!' },
  },
  async created() {
    this.greetingMessage = await this.$store.dispatch('common/getEmptyAppGreeting')
  },
  methods: { },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .button-div {
    margin: auto;
    margin-top: 40px;
    width: 288px;

    .vasion-button {
      button {
        height: 56px;
        border-radius: 8px;

        .md-button-content {
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          line-height: 56px;
          vertical-align: middle;

          svg {
            fill: $white;
            line-height: 56px;
            vertical-align: middle;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  .inner-div {
    padding-top: 124px;
    width: 100%;
  }

  .instructional-message-div {
    margin-top: 24px;
  }

  .main-div {
    background-color: white;
    text-align: center;
    width: 100%;
  }

  #vasion-empty-icon{
    width: 356px;
    height: 376px;
  }

  @media(max-width: $phone){
    #vasion-empty-icon{
      width: 90%;
    }
    .vasion-large-message{
      font-size: 30px;
    }
    .inner-div {
      padding-top: 10px;
    }
  }
</style>
